import styled from 'styled-components'
import { Link } from 'widgets'

const MenuEntry = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  &.active a {
    color: #00a0ff;
  }

  button {
    min-height: 70px;
    min-width: 170px;
  }
`
const MenuSubEntry = styled.div`
  padding-bottom: 10px;

  &.active a {
    color: #00a0ff;
  }
`
const MenuLink = styled(Link)`
  display: inline-block;
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.04em;
  padding: 4px 0;

  &:hover {
    color: #00a0ff;
  }
`

export { MenuEntry, MenuSubEntry, MenuLink }
