import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'
import { Box } from 'widgets/Box'

const Wrapper = styled(Box)<{ background?: string; borderRadius?: string; width?: string; secondary?: boolean }>`
  width: 100%;
  cursor: pointer;
  background: #fff;
  border: 2px solid #68a5ff;
  border-radius: 100px;
  position: relative;
  font-size: 14px;
  ${({ width }) => {
    if (width) {
      return `
        padding: 8px 0px;
        width: ${width};
      `
    }
    return `
      padding: 8px 24px;
    `
  }}

  &:disabled {
    background-color: #262b34;
  }

  & > div {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    justify-content: center;
    color: #000;

    @media (min-width: 991px) {
      line-height: 24px;
    }
  }

  &[data-hover='true'],
  &[data-loading='true'] {
    cursor: default;
  }

  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &[disabled],
  &[disabled='true'] {
    cursor: not-allowed;
    background: #323333;
    border-color: transparent;

    * {
      color: #646666;
    }
  }

  :hover:not(&[disabled], &[disabled='true']) {
    box-shadow: ${({ secondary }) => (!secondary ? '0px 4px 20px #00A0FF' : '')};
    background: #00a0ff;

    > div {
      color: #fff;
    }
  }
`

const BaseButton: React.FC<any> = ({
  loading = false,
  disabled = false,
  disableHover = false,
  background,
  borderRadius,
  width,
  onClick,
  children,
  secondary = false,
  ...props
}) => {
  return (
    <Wrapper
      as="button"
      background={background}
      borderRadius={borderRadius}
      width={width}
      data-loading={loading.toString()}
      data-hover={disableHover.toString()}
      disabled={disabled}
      secondary={secondary}
      onClick={() => {
        if (!loading && !disabled && onClick) onClick()
      }}
      {...props}
    >
      <div>{children}</div>
      {loading && <Spin spinning />}
    </Wrapper>
  )
}

export default BaseButton
