import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

const initialState = {
  txHistory: {
    data: [],
    page: 0,
    total: 0,
  },
}

export default createReducer(initialState, (builder) =>
  builder.addCase(actions.setTransactionHistoryData, (state, { payload }) => {
    state.txHistory = payload as any
  }),
)
