import React from 'react'
import styled from 'styled-components'
import { flexbox } from 'styled-system'
import Box from './Box'
import { FlexProps } from './types'

const FlexWrap = styled(Box)<FlexProps>`
  ${flexbox}
`

interface Props extends FlexProps {
  children: any
}

const Flex = ({ children, ...rest }: Props) => {
  const { display } = rest
  return (
    <FlexWrap {...rest} style={display ? {} : { display: 'flex' }}>
      {children}
    </FlexWrap>
  )
}

export default Flex
