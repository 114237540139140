import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Input } from 'antd'
import { CopyIcon } from 'widgets/Svg'
import styled from 'styled-components'
import { InputCopyType } from './types'

const Wrapper = styled.div`
  position: relative;

  .style-input-title {
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }

  h3 {
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    line-height: 16px;
    margin-bottom: 16px;
  }
  input.ant-input {
    color: #fdfffa;
    font-size: 14px;
    line-height: 24px;
    padding: 0 4px;
    outline: unset;
    cursor: pointer;
  }
  input {
    height: 30px;
    cursor: pointer;
  }
  .ant-input-affix-wrapper {
    /* border-radius: 5px; */
    input {
      background: transparent;
    }
  }

  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-readonly,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:active,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:valid {
    border: unset;
    outline: unset;
    box-shadow: unset;
    background: unset;
  }

  .wrapper-input {
    padding: 8px 12px;
    border: 1px solid rgba(253, 255, 250, 0.1);

    position: relative;
    cursor: pointer;

    span {
      padding: 0;
    }
  }
  .copy-success {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    color: #00a0ff;
    background: #141c1c;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    align-items: center;
    display: flex;
    justify-content: center;
    /* border-radius: 8px; */
    font-size: 14px;

    &:active {
      opacity: 0.9;
    }
  }
  .link {
    position: absolute;
    z-index: 22;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: block;
  }
  .icon-fly {
    img {
      width: 13.5px;
    }
  }
  .not-allowed .wrapper-input,
  .not-allowed input,
  .not-allowed img {
    cursor: not-allowed !important;
  }

  .ant-input-suffix {
    cursor: pointer;
    margin: 0;

    svg {
      width: 16px;
      height: 16px;
      fill: #989996;
    }
  }
`

const InputCopy = ({
  className,
  title,
  value,
  disabled,
  accessCopy = true,
  link,
  placeholder,
  onChange,
  displayValue,
}: InputCopyType) => {
  const [copySuccess, setCopySuccess] = useState(false)

  const onCopy = () => {
    if (disabled || !accessCopy || !value) return
    setCopySuccess(true)
    setTimeout(() => {
      setCopySuccess(false)
    }, 1000)
  }

  return (
    <Wrapper className={className}>
      {title && <p className="text-[18px] mb-2">{title}</p>}
      <CopyToClipboard onCopy={onCopy} text={value}>
        <div className={`style-input-copy ${disabled ? 'not-allowed' : ''}`}>
          <div className="unit">
            <div className="wrapper-input">
              <Input
                onChange={onChange}
                suffix={<CopyIcon className="icon-copy" />}
                value={displayValue || value || ''}
                placeholder={placeholder || ''}
                disabled
              />
              {copySuccess && <div className="copy-success">Success</div>}
              {link && <Link className="link" to={link} />}
            </div>
          </div>
        </div>
      </CopyToClipboard>
    </Wrapper>
  )
}

export default InputCopy
