import { Box } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { Dropdown } from 'antd'
import { useTranslation } from 'contexts/Localization'
import { formatCode } from 'helpers/CommonHelper'
import { deleteAuthToken } from 'helpers/FetchHelper'
import { setGetNameSevice } from 'hooks/setGetNameService'
import useCheckAdmin from 'hooks/useCheckAdmin'
import useCheckSharingRole from 'hooks/useCheckSharingRole'
import useIsMobile from 'hooks/useIsMobile'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import { updateUserInfo } from 'state/user/actions'
import { useUserData } from 'state/user/hooks/useUserData'
import { useGetUsdtWallet } from 'state/wallet/useGetWalletData'
import styled from 'styled-components'
import { Link } from 'widgets'
import BaseButton from 'widgets/BaseButton'
import CollapseIcon from './CollapseIcon'
import DropdownUserMenu from './DropdownUserMenu'
import MobileSlider from './MobileSlider'
import { MenuEntry } from './types'

const WrapperHeader = styled.header<{ background: string }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  transition: 0.25s;
  z-index: 15;
  background: ${({ background }) => background};

  .content {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
    background: black;
    width: 100%;
    .menu {
      width: 100%;
      max-width: 1172px;
      display: flex;
      justify-content: space-between;
      margin: auto;
      padding: 16px 16px 12px;

      ${({ theme }) => theme.mediaQueries.md} {
        padding: 32px 16px 12px;
      }

      .menu-mid {
        font-weight: bold;
        gap: 32px;
        font-size: 20px;
        display: flex;
        align-items: center;
      }

      .menu-right {
        display: flex;
        align-items: baseline;

        .notify-icon {
          width: 30px;
          position: relative;
          margin-right: 16px;
          cursor: pointer;

          span {
            position: absolute;
            top: 2px;
            right: -3px;
            width: 18px;
            height: 18px;
            background: #f41706;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;
          }
          img {
            width: 100%;
          }
        }

        .menu-actions-desktop {
          position: relative;
          display: flex;
          align-items: center;

          a {
            width: 100%;

            &:not(:last-child) {
              margin-right: 13px;
            }
          }

          button {
            &:not(:last-child) {
              margin-right: 13px;
            }
          }
          .sign-in {
            border-radius: 0;
            height: 50px;
            aspect-ratio: 22/5;
            padding: 1px;
            background: linear-gradient(-35deg, #979efd 20%, #32eeec 50%, #ffffff 80%, #fd9abd 95%, #9194fd 100%);

            -webkit-clip-path: polygon(7% 0, 93% 0, 100% 29%, 100% 71%, 93% 100%, 7% 100%, 0 71%, 0 29%);
            -moz-clip-path: polygon(7% 0, 93% 0, 100% 29%, 100% 71%, 93% 100%, 7% 100%, 0 71%, 0 29%);
            -ms-clip-path: polygon(7% 0, 93% 0, 100% 29%, 100% 71%, 93% 100%, 7% 100%, 0 71%, 0 29%);
            clip-path: polygon(7% 0, 93% 0, 100% 29%, 100% 71%, 93% 100%, 7% 100%, 0 71%, 0 29%);

            > div {
              color: white;
              align-items: center;
              display: flex;
              gap: 8px;
              font-size: 20px;
              font-weight: bold;

              width: 100%;
              height: 100%;
              background-color: #000;
              -webkit-clip-path: polygon(7% 0, 93% 0, 100% 29%, 100% 71%, 93% 100%, 7% 100%, 0 71%, 0 29%);
              -moz-clip-path: polygon(7% 0, 93% 0, 100% 29%, 100% 71%, 93% 100%, 7% 100%, 0 71%, 0 29%);
              -ms-clip-path: polygon(7% 0, 93% 0, 100% 29%, 100% 71%, 93% 100%, 7% 100%, 0 71%, 0 29%);
              clip-path: polygon(7% 0, 93% 0, 100% 29%, 100% 71%, 93% 100%, 7% 100%, 0 71%, 0 29%);
              border-radius: inherit;
            }
          }
        }

        svg {
          width: 35px;
          height: 35px;
          margin: 15px;
        }
      }
    }
  }
`

const Line = styled.div`
  width: 70%;
  margin: 0 auto;
  height: 1px;
  background: linear-gradient(90deg, #ffffff00 0%, #91c8ff 50%, #ffffff00 100%);
`

const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(-50%, -20%);

  box-shadow: 1px 1px 500px #2c96ff1a;
  background-color: #2c96ff1a;
  border-radius: 100%;
  filter: blur(20px);
  -webkit-filter: blur(20px);

  width: 500px;
  aspect-ratio: 1/1;
  z-index: -1;
  display: none;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
  }
`

const UserBox = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    margin-left: 32px;
    cursor: pointer;
  }

  svg {
    cursor: pointer;

    :hover {
      path {
        stroke: #00a0ff;
      }
    }
  }
`

interface Props {
  menuItems: MenuEntry[]
  hasSider?: boolean
}

const mobileWith = 991

const Header: React.FC<Props> = ({ menuItems, hasSider = false }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [isScroll, updateIsScroll] = useState(false)

  const dispatch = useDispatch()
  const isMobile = useIsMobile(mobileWith)
  const { account } = useWeb3React()
  const userData = useUserData()
  const myWallet = useGetUsdtWallet()
  const [isAdminList] = useCheckAdmin(account)
  const [isSharingAdminRole] = useCheckSharingRole(account)

  const [showSlider, setShowSlider] = useState(false)
  const [web3DomainName, setWeb3DomainName] = useState<string>('')

  useMemo(async () => {
    if (account && setGetNameSevice) {
      const nameSevice = await setGetNameSevice(account)

      setWeb3DomainName(nameSevice)
    }
  }, [account])

  const toggleWallet = () => {
    dispatch(setModalConnect({ toggle: true }))
  }

  const toggleMenu = () => {
    setShowSlider((prev) => !prev)
  }

  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : undefined

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      updateIsScroll(true)
    } else {
      updateIsScroll(false)
    }
  }

  const handleSignOut = useCallback(() => {
    dispatch(updateUserInfo(undefined))
    const isDelete = deleteAuthToken()
    if (isDelete) {
      router.push('/sign-in')
    }
  }, [dispatch, router])

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener('scroll', changeBackground)
  })

  const menuItemList = useMemo(() => {
    const result: any[] = []

    if (isAdminList)
      result.push({
        key: '1',
        label: (
          <Link href={`/config/${account}`} className="hover:bg-[#34baff] p-2">
            Config Box
          </Link>
        ),
      })

    if (isSharingAdminRole)
      result.push({
        key: '1',
        label: (
          <Link href={`/config/sharing/${account}`} className="hover:bg-[#34baff] p-2">
            Config Sharing
          </Link>
        ),
      })

    return result
  }, [account, isAdminList, isSharingAdminRole])

  return (
    <>
      <WrapperHeader background={!isScroll ? '#0A14141a' : '#0A14141a'}>
        <div className="content">
          <div className="menu flex">
            {!hasSider ? (
              <>
                <div className="flex items-center gap-[60px]">
                  <div className="menu-logo">
                    <Link href="/">
                      <img src="/images/logo.png" alt="" className="w-full max-w-[170px] aspect-[170/62]" />
                    </Link>
                  </div>

                  {!isMobile && (
                    <div className="menu-mid">
                      <Link href="/">Home</Link>
                      <Link href="/">Ino</Link>
                      <Link href="/my-nft">My NFT</Link>
                      {account && isAdminList && (
                        // <Dropdown
                        //   className="cursor-pointer"
                        //   menu={{
                        //     items: menuItemList,
                        //   }}
                        //   placement="topLeft"
                        // >
                        //   <p>Config</p>
                        // </Dropdown>
                        <Link href={`/config/${account}`}>Config</Link>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div />
            )}

            <div className="menu-right">
              {isMobile ? (
                <CollapseIcon active={showSlider} toggleMenu={toggleMenu} />
              ) : (
                <div className="menu-actions-desktop">
                  {!userData ? (
                    <>
                      <BaseButton className="auth-btn sign-in" onClick={toggleWallet}>
                        {web3DomainName || formatCode(account, 5, 5) || t('Connect Wallet')}
                      </BaseButton>
                    </>
                  ) : (
                    <UserBox>
                      <Dropdown
                        overlay={
                          <DropdownUserMenu
                            userData={userData}
                            toggleMenu={toggleMenu}
                            onSignOut={handleSignOut}
                            myWallet={myWallet}
                          />
                        }
                        placement="bottomLeft"
                        trigger={['click']}
                        open={showSlider}
                        onOpenChange={toggleMenu}
                      >
                        <Box as="img" src={userData?.avatar} alt="" />
                      </Dropdown>
                    </UserBox>
                  )}
                </div>
              )}
            </div>
          </div>

          <Line />
          <Shadow />
        </div>
      </WrapperHeader>
      {isMobile && (
        <MobileSlider
          visible={showSlider}
          links={menuItems}
          accountEllipsis={accountEllipsis}
          toggleWallet={toggleWallet}
          toggleMenu={toggleMenu}
          userData={userData}
          onSignOut={handleSignOut}
          myWallet={myWallet}
          web3DomainName={web3DomainName}
          isWhiteList={isAdminList}
          isSharingAdminRole={isSharingAdminRole}
        />
      )}
    </>
  )
}

export default Header
