import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'
import { NftDataType } from './types'

export interface ModalState {
  myNftList: NftDataType[] | null
  listAuction: any[]
}

const initialState: ModalState = {
  myNftList: null,
  listAuction: [],
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setMyNft, (state, { payload }) => {
      state.myNftList = payload
    })
    .addCase(actions.setListAuction, (state, { payload }) => {
      state.listAuction = payload
    }),
)
