import { MenuEntry } from './types'

/**
 * label: string
 * icon: svg
 * href: https || /path || 'function'
 * calloutClass: 'rainbow' || className => background rainbow and custom css
 * initialOpenState: true || false
 * items: array sample parent
 * att: attach => text || icon.png
 */

export const links: MenuEntry[] = [
  {
    label: 'Airdrop',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/shop.svg',
    href: 'https://dapp.ukadoge.com/airdrop',
  },
  {
    label: 'SHOP',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/mynft.svg',
    href: 'https://dapp.ukadoge.com/shop',
  },
  {
    label: 'Marketplace',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/shop.svg',
    href: 'https://dapp.ukadoge.com/marketplace',
  },
  {
    label: 'Auction',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/market.svg',
    href: 'https://dapp.ukadoge.com/auction',
  },
  {
    label: 'FARM',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/mynft.svg',
    href: 'https://dapp.ukadoge.com/farming',
  },
  {
    label: 'STAKING',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/mynft.svg',
    href: 'https://dapp.ukadoge.com/staking',
  },
  {
    label: 'Boss',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/auction.svg',
    href: 'https://dapp.ukadoge.com/boss',
  },
  {
    label: 'Contest',
    icon: 'https://zuki-moba.s3.ap-southeast-1.amazonaws.com/icon/farm.svg',
    href: 'https://dapp.ukadoge.com/contest',
  },
]

export const menuItems = [
  {
    label: 'Home',
    icon: '',
    href: '/',
    actives: ['/'],
  },
  {
    label: 'My NFT',
    icon: '',
    href: '/my-nft',
    actives: ['/my-nft'],
  },
  {
    label: 'Ino',
    icon: '',
    href: '/#ino',
    actives: ['/#ino'],
  },
  {
    label: 'Config',
    icon: '',
    href: '/config',
    actives: ['/config'],
  },
]

export default links
