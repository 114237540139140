import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <g clipPath="url(#clip0_19613_14049)">
        <path
          d="M5.50426 7.56378L8.99986 4.06827L12.4973 7.56558L14.5313 5.53158L8.99986 0L3.47035 5.52978L5.50426 7.56378ZM0 8.99972L2.03406 6.96566L4.06799 8.99959L2.03395 11.0336L0 8.99972ZM5.50426 10.4362L8.99986 13.9317L12.4972 10.4345L14.5322 12.4674L14.5313 12.4685L8.99986 18L3.47017 12.4704L3.46729 12.4675L5.50426 10.4362ZM13.9321 9.00084L15.9661 6.96678L18.0001 9.00071L15.966 11.0348L13.9321 9.00084Z"
          fill="#FFDA55"
        />
        <path
          d="M11.0636 8.99854H11.0645L9.00051 6.93457L7.47519 8.45989H7.4751L7.29987 8.63521L6.93843 8.99674L6.93555 8.99953L6.93843 9.0025L9.00051 11.0647L11.0645 9.0007L11.0655 8.99953L11.0636 8.99854Z"
          fill="#FFDA55"
        />
      </g>
      <defs>
        <clipPath id="clip0_19613_14049">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  )
}

export default Icon
