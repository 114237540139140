import { SHARING_ROLE_KEY } from 'config/constants/endpoints'
import { useCallback, useEffect, useState } from 'react'
import { useSharingContract } from './useContract'

const useCheckSharingRole = (account: string): any => {
  const contractMethod = useSharingContract()
  const [data, setData] = useState(false)

  const getData = useCallback(async () => {
    if (contractMethod && account) {
      try {
        const result = await contractMethod.hasRole(SHARING_ROLE_KEY, account)
        setData(result)
      } catch (error) {
        console.error('useCheckSharingRole', error)
      }
    }
  }, [account, contractMethod])

  useEffect(() => {
    getData()
  }, [getData])

  return [data, getData]
}

export default useCheckSharingRole
