import Header from './Header'
import { menuItems } from './config'

const Menu = () => {
  return (
    <>
      <Header menuItems={menuItems} />
    </>
  )
}

export default Menu
