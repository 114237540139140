import NextLink from 'next/link'
import React, { AnchorHTMLAttributes } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import getExternalLinkProps from 'utils/getExternalLinkProps'
import Text from '../Text/Text'
import { LinkProps } from './types'

const StyledLink = styled(Text)<LinkProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  &:hover {
    text-decoration: underline;
  }
`

export const Link: React.FC<LinkProps> = ({ external, children, ...props }) => {
  const isExternalLink = props.href?.startsWith('http') || external
  const internalProps = isExternalLink ? getExternalLinkProps() : {}

  return (
    <NextLink href={props.href || ''}>
      <a {...internalProps} {...props}>
        {children}
      </a>
    </NextLink>
  )
}

export const RouteLink: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({ href, ...otherProps }) => {
  const isHttpLink = href?.startsWith('http')
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Tag: any = isHttpLink ? 'a' : NavLink
  const props = isHttpLink ? { href } : { to: href }
  return <Tag {...props} {...otherProps} />
}
