import React from 'react'
import styled from 'styled-components'
import { Link } from 'widgets'
import { Login, Config } from './types'
import { connectorLocalStorageKey } from '../../../packages/uikit/src/widgets/WalletModal'

const WalletStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 24px 12px;
  cursor: pointer;
  height: 100%;

  background: #ffffff1a;
  transition: 0.3s ease;

  clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
  -webkit-clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
  -moz-clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);
  -ms-clip-path: polygon(5% 0, 95% 0, 100% 10%, 100% 90%, 95% 100%, 5% 100%, 0 90%, 0 10%);

  ${({ theme }) => theme.mediaQueries.sm} {
    gap: 20px;
  }

  :hover {
    transform: scale(1.03);
  }
  .modal-title {
    color: #fff;
    font-weight: bold;
    font-size: 16px;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 20px;
    }
  }

  .modal-image {
  }

  svg {
    width: 40px;
    height: auto;
  }
`

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss }) => {
  const { title, icon: Icon } = walletConfig

  let linkAction: any = {
    onClick: () => {
      login(walletConfig.connectorId)
      window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
      onDismiss()
    },
  }

  if (typeof window !== 'undefined' && !window.ethereum && walletConfig.href) {
    linkAction = {
      style: {
        textDecoration: 'none',
      },
      as: Link,
      href: walletConfig.href,
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  }

  return (
    <WalletStyled id={`wallet-connect-${title.toLocaleLowerCase()}`} {...linkAction}>
      <div className="modal-image">
        <Icon width="40px" />
      </div>
      <div className="modal-title">{title}</div>
    </WalletStyled>
  )
}

export default WalletCard
