import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export interface CollectionState {
  balances: any
}

const initialState: CollectionState = {
  balances: {
    data: [],
  },
}

export default createReducer(initialState, (builder) =>
  builder.addCase(actions.setBalanceData, (state, { payload }) => {
    state.balances = payload
  }),
)
