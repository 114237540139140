/* eslint-disable no-restricted-syntax */
import { TreeNode } from './types'

export function findNodeById(root: TreeNode | undefined, id: string): TreeNode | null {
  if (!root) {
    return null // Handle the case where root is undefined
  }

  if (root.id === id) {
    return root
  }

  if (!root.children) {
    return null // Handle the case where children is undefined or null
  }

  for (const child of root.children) {
    const result = findNodeById(child, id)
    if (result) {
      return result
    }
  }

  return null // Node with the given ID not found
}
