import styled, { DefaultTheme } from 'styled-components'
import { space, typography } from 'styled-system'
import getThemeValue from 'utils/getThemeValue'
import { TextProps } from './types'

interface ThemedProps extends TextProps {
  theme: DefaultTheme
}

interface Props extends TextProps {
  nowrap?: boolean
}

const getColor = ({ color, theme }: ThemedProps) => {
  return getThemeValue(`colors.${color}`, color)(theme)
}

const getFontSize = ({ fontSize, small }: TextProps) => {
  return small ? '14px' : fontSize || '16px'
}

const Text = styled.div<Props>`
  color: ${getColor};
  ${({ small }) =>
    small
      ? `
      font-size: 14px;
      `
      : ``}
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  line-height: 1.5;
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${space}
  ${typography}
  white-space:  ${({ nowrap }) => (nowrap ? 'nowrap' : 'unset')};
`

Text.defaultProps = {
  color: 'text',
  small: false,
}

export default Text
