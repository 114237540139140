import { Button, Modal } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setModalSuccess } from 'state/modal/actions'
import styled from 'styled-components'
import { CloseIcon } from 'widgets'
import { AppState } from '../../state/index'

const ModalContent = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;

  img {
    margin: auto;
    margin-bottom: 24px;
  }

  p {
    &:nth-child(2) {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 24px;
    }

    &:nth-child(3) {
      color: #fff;
      font-size: 44px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 44px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 250px;
    height: 48px;
    padding: 12px 10px;
    border-radius: 12px;
    border: 1px solid var(--blue, #00bff3);
    background: var(--blue, linear-gradient(270deg, #00bff3 0%, #0081de 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`

const ModalSuccess = () => {
  const dispatch = useDispatch()
  const { toggle, dataModal } = useSelector((state: AppState) => state.modal.modalSuccess)

  const handleCancel = () => {
    dispatch(setModalSuccess({ toggle: false }))
  }

  return (
    <Modal
      key="modal-connect"
      open={toggle}
      centered
      width={532}
      footer={null}
      closeIcon={
        <div className="flex items-center justify-center w-full h-full">
          <CloseIcon />
        </div>
      }
      onCancel={handleCancel}
    >
      <ModalContent>
        <img src="/images/icons/ticked.png" alt="" />
        <p>Success bid</p>
        <p>{dataModal?.priceBid}</p>

        <button type="button" onClick={handleCancel}>
          Biding Orders
        </button>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ModalSuccess)
