import { createReducer } from '@reduxjs/toolkit'
import {
  ModalMyNftType,
  setModalBid,
  setModalBuyNow,
  setModalClaimSuccess,
  setModalConnect,
  setModalLoading,
  setModalMyNft,
  setModalNftDetail,
  setModalSeeAll,
  setModalSuccess,
} from './actions'

export interface ModalState {
  modalConnect: any
  modalBuyNow: any
  modalMyNft: ModalMyNftType
  modalNftDetail: ModalMyNftType
  modalLoading: ModalMyNftType
  modalSeeAll: ModalMyNftType
  modalClaimSuccess: ModalMyNftType
  modalBid: ModalMyNftType
  modalSuccess: ModalMyNftType
}

const initialState: ModalState = {
  modalConnect: { toggle: false, dataModal: null },
  modalBuyNow: { toggle: false, dataModal: null },
  modalMyNft: { toggle: false, dataModal: null },
  modalNftDetail: { toggle: false, dataModal: null },
  modalSeeAll: { toggle: false, dataModal: null },
  modalClaimSuccess: { toggle: false, dataModal: null },
  modalBid: { toggle: false, dataModal: null },
  modalLoading: { toggle: false },
  modalSuccess: { toggle: false, dataModal: null },
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setModalConnect, (state, { payload }) => {
      state.modalConnect = payload
    })
    .addCase(setModalBuyNow, (state, { payload }) => {
      state.modalBuyNow = payload
    })
    .addCase(setModalMyNft, (state, { payload }) => {
      state.modalMyNft = payload
    })
    .addCase(setModalNftDetail, (state, { payload }) => {
      state.modalNftDetail = payload
    })
    .addCase(setModalLoading, (state, { payload }) => {
      state.modalLoading = payload
    })
    .addCase(setModalSeeAll, (state, { payload }) => {
      state.modalSeeAll = payload
    })
    .addCase(setModalClaimSuccess, (state, { payload }) => {
      state.modalClaimSuccess = payload
    })
    .addCase(setModalBid, (state, { payload }) => {
      state.modalBid = payload
    })
    .addCase(setModalSuccess, (state, { payload }) => {
      state.modalSuccess = payload
    }),
)
