/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { Flex, Link } from 'widgets'
import { TelegramIcon, TwitterIcon } from '../../../packages/uikit/src/components/Svg'

const FOOTER_LINK = [
  {
    title: 'About',
    href: '#',
  },
  {
    title: 'Tokenomics',
    href: '#',
  },
  {
    title: 'Roadmap',
    href: '#',
  },
  {
    title: 'How To Buy',
    href: '#',
  },
  {
    title: 'FAQ',
    href: '#',
  },
  {
    title: 'Contact',
    href: '#',
  },
]

const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 42px 16px;
  background-color: #04071a;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 60px 16px;
  }

  .container {
    width: 100%;
    max-width: 1172px;
    margin: 0 auto;
  }

  img {
    width: 200px;
  }
`

const Footer = () => {
  const { t } = useTranslation()

  return (
    <FooterWrapper>
      <div className="w-full flex flex-col gap-8 container">
        <div className="flex flex-col pl-6 border-l-[8px] border-solid border-[#95C5F4]">
          <Link href="/">
            <img src="/images/logo.png" alt="" className="w-full max-w-[170px] aspect-[170/62]" />
          </Link>
          <Link href="https://soraai.bot" external>
            <p className="text-[24px] text-[#95C5F4]">www.soraai.bot</p>
          </Link>
        </div>

        <div className="flex items-center gap-6">
          <Link href="#" external>
            <TelegramIcon />
          </Link>
          <Link href="#" external>
            <TwitterIcon />
          </Link>
        </div>
      </div>
    </FooterWrapper>
  )
}

export default Footer
