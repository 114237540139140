import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.61539 0.906977C8.61539 0.406067 9.02866 0 9.53846 0H14.4615C14.9713 0 15.3846 0.406067 15.3846 0.906977C15.3846 1.40789 14.9713 1.81395 14.4615 1.81395H9.53846C9.02866 1.81395 8.61539 1.40789 8.61539 0.906977ZM12 4.23256C6.39219 4.23256 1.84615 8.6993 1.84615 14.2093C1.84615 19.7193 6.39219 24.186 12 24.186C17.6078 24.186 22.1538 19.7193 22.1538 14.2093C22.1538 8.6993 17.6078 4.23256 12 4.23256ZM0 14.2093C0 7.69748 5.37258 2.4186 12 2.4186C18.6274 2.4186 24 7.69748 24 14.2093C24 20.7211 18.6274 26 12 26C5.37258 26 0 20.7211 0 14.2093ZM12 8.46512C12.5098 8.46512 12.9231 8.87118 12.9231 9.37209V14.2093C12.9231 14.7102 12.5098 15.1163 12 15.1163C11.4902 15.1163 11.0769 14.7102 11.0769 14.2093V9.37209C11.0769 8.87118 11.4902 8.46512 12 8.46512Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
