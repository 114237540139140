/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import momentTimezone from 'moment-timezone'

export const currentTimestamp = () => new Date().getTime()

export const removeEmpty = (obj: any) =>
  Object.entries(obj)
    .map(([k, v]) => [k, v && typeof v === 'object' && !Array.isArray(v) ? removeEmpty(v) : v])
    // eslint-disable-next-line eqeqeq
    .reduce((a, [k, v]) => (v == null || v == undefined || v == '' ? a : ((a[k] = v), a)), {})

export const formatDate = (date, format = 'YYYY/MM/DD HH:mm:ss') => {
  // const country = JSON.parse(localStorage.getItem("userInfo"));
  const country = 'Asia/Ho_Chi_Minh'
  if (date) {
    const tz = momentTimezone(date)
    const time = tz.tz(country).format(format)
    return time
  }
  return ''
}

export const formatCode = (text: string, start: number, end: number, concat = '...'): string => {
  if (!text) return text
  const total = start + end
  const textStr = text.toString()
  const { length } = textStr
  if (total >= length) return text
  return [textStr.slice(0, start), textStr.slice(length - end)].join(concat)
}

export const validateEmail = (email: string) => {
  return Boolean(
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      ),
  )
}

export function convertKeysToCamelCase(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item))
  }

  return Object.keys(obj).reduce((camelCaseObj, key) => {
    const camelCaseKey = key.replace(/_(\w)/g, (match, letter) => letter.toUpperCase())
    camelCaseObj[camelCaseKey] = convertKeysToCamelCase(obj[key])
    return camelCaseObj
  }, {})
}
