import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none" {...props}>
      <g filter="url(#filter0_d_19611_15638)">
        <rect
          x="9.36328"
          y="9.86328"
          width="3.27273"
          height="3.27273"
          rx="1.63636"
          fill="url(#paint0_linear_19611_15638)"
        />
      </g>
      <path
        d="M3.72656 7.28828L10.9993 3.07775L18.272 7.28828V15.7117L10.9993 19.9223L3.72656 15.7117V7.28828Z"
        stroke="url(#paint1_linear_19611_15638)"
      />
      <defs>
        <filter
          id="filter0_d_19611_15638"
          x="0.363281"
          y="0.863281"
          width="21.2734"
          height="21.2725"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.603576 0 0 0 0 0.866634 0 0 0 0 0.979374 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19611_15638" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19611_15638" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_19611_15638"
          x1="11.6261"
          y1="9.86328"
          x2="11.6339"
          y2="13.136"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0473389" stopColor="#01FF67" />
          <stop offset="1" stopColor="#01FFFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_19611_15638"
          x1="13.9748"
          y1="2.5"
          x2="14.0245"
          y2="20.4999"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0473389" stopColor="#43FF01" />
          <stop offset="1" stopColor="#01FFFF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
