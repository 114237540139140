import React from 'react'
import { Layout } from 'antd'
import Menu from 'components/Menu'
import Footer from 'components/Footer'
import { Box } from 'widgets'
import AuthHeader from 'components/Menu/AuthHeader'
import { useRouter } from 'next/router'

const { Content } = Layout

const PublicLayout = ({ children, isAuth, isLogged }) => {
  const router = useRouter()

  if (isLogged || ['/', '/home'].includes(router?.pathname) || isAuth) {
    return (
      <Layout style={{ backgroundColor: '#000311' }}>
        <div id="google_translate_element" />
        {isAuth ? <AuthHeader /> : <Menu />}
        <Content>
          <Box minHeight={isAuth ? '' : '100vh'} position="relative">
            {children}
          </Box>
        </Content>
        {!router?.pathname?.includes('profit-share') && <Footer />}
      </Layout>
    )
  }

  router.replace('/')
  return <></>
}

export default PublicLayout
