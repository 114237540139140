import { createReducer } from '@reduxjs/toolkit'
import { StakingState } from './type'
import { updateReportStaking } from './action'

const initialState: StakingState = {
  report: null,
}

export default createReducer<StakingState>(initialState, (builder) =>
  builder.addCase(updateReportStaking, (state, action) => {
    return {
      ...state,
      report: action?.payload,
    }
  }),
)
