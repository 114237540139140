import { useState, useCallback, useEffect } from 'react'
import { useInoContract } from './useContract'

const useCheckAdmin = (account: string): any => {
  const contractMethod = useInoContract()
  const [data, setData] = useState(false)

  const getData = useCallback(async () => {
    if (contractMethod && account) {
      try {
        const result = await contractMethod.adminList(account)
        setData(result)
      } catch (error) {
        console.error('useCheckAdmin', error)
      }
    }
  }, [account, contractMethod])

  useEffect(() => {
    getData()
  }, [getData])

  return [data, getData]
}

export default useCheckAdmin
