import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'widgets'

const WrapperHeader = styled.header<{ background: string }>`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 88px;
  transition: 0.25s;
  z-index: 999;

  background: ${({ background }) => background};
  backdrop-filter: blur(30px);
  border-bottom: 1px solid rgba(250, 255, 255, 0.1);

  .content {
    position: relative;
    max-width: 1440px;
    padding: 0 16px;
    margin: 0 auto;
    width: 100%;

    display: flex;
    justify-content: center;

    ${({ theme }) => theme.mediaQueries.md} {
      justify-content: space-around;
    }

    ${({ theme }) => theme.mediaQueries.xl} {
      padding: 0 32px;
    }

    img {
      width: 5rem;
    }
  }
`

const AuthHeader = () => {
  const [isScroll, updateIsScroll] = useState(false)

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      updateIsScroll(true)
    } else {
      updateIsScroll(false)
    }
  }

  useEffect(() => {
    changeBackground()
    window.addEventListener('scroll', changeBackground)
  })

  return (
    <WrapperHeader background={!isScroll ? '#0A14141a' : '#0A14141a'}>
      <div className="content">
        <Link href="/">
          <img src="/images/logo.svg" alt="" />
        </Link>
      </div>
    </WrapperHeader>
  )
}

export default AuthHeader
