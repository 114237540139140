import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none" {...props}>
      <path
        d="M11.8637 30.2805C12.7483 30.7566 13.4523 30.4392 13.4523 29.4869V18.3871C13.4523 17.4349 12.7452 16.4846 11.8637 16.0085L1.62182 10.2989C0.737189 9.9815 0.0332032 10.2989 0.0332031 11.0924V22.1942C0.0332031 23.1474 0.740286 24.0967 1.62182 24.5728L11.8637 30.2805ZM2.79753 7.90477L13.4172 13.6647C14.3493 14.1446 15.652 14.1446 16.5841 13.8243L27.2038 8.21831C28.1369 7.73832 28.1369 7.09769 27.2038 6.61963L17.0569 0.859749C16.3116 0.379758 14.8221 0.379758 13.8889 0.859749L2.79753 6.30609C1.86439 6.78705 1.86439 7.42768 2.79753 7.90477ZM16.549 29.4347C16.549 30.4372 17.2654 30.7711 18.1593 30.2698L28.3578 25.4312C29.2548 24.9289 29.9681 23.9293 29.9681 22.9277V11.2415C29.9681 10.2389 29.2517 9.90505 28.3578 10.4063L18.1593 15.2449C17.2623 15.7472 16.549 16.7469 16.549 17.7484V29.4347Z"
        fill="#06E0FE"
      />
    </Svg>
  )
}

export default Icon
