import { createAction } from '@reduxjs/toolkit'

export type ModalMyNftType = {
  toggle: boolean
  dataModal?: any
}

export const setModalConnect = createAction<any>('modal/setModalConnect')
export const setModalBuyNow = createAction<any>('modal/setModalBuyNow')
export const setModalMyNft = createAction<ModalMyNftType>('modal/setModalMyNft')
export const setModalNftDetail = createAction<ModalMyNftType>('modal/setModalNftDetail')
export const setModalLoading = createAction<ModalMyNftType>('modal/setModalLoading')
export const setModalSeeAll = createAction<ModalMyNftType>('modal/setModalSeeAll')
export const setModalClaimSuccess = createAction<ModalMyNftType>('modal/setModalClaimSuccess')
export const setModalBid = createAction<ModalMyNftType>('modal/setModalBid')
export const setModalSuccess = createAction<ModalMyNftType>('modal/setModalSuccess')
