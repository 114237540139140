import SID, { getSidAddress } from '@siddomains/sidjs'
import Web3 from 'web3'

export async function setGetNameSevice(address?: string, cbs?: any) {
  const rpc = 'https://bsc-dataseed.binance.org/'
  const provider = new Web3.providers.HttpProvider(rpc)
  const chainId = '56'
  const sid = new SID({ provider, sidAddress: getSidAddress(chainId) })

  const name = await sid.getName(address)

  if (cbs && name.name) {
    cbs(name.name)
  }

  if (name.name) {
    return name.name
  }

  return ``
}

export async function setGetAddressByEns(ens?: string, cbs?: any) {
  const rpc = 'https://bsc-dataseed.binance.org/'
  const provider = new Web3.providers.HttpProvider(rpc)
  const chainId = '56'
  const sid = new SID({ provider, sidAddress: getSidAddress(chainId) })

  try {
    const address = await sid.name(ens).getAddress()

    if (cbs) {
      cbs(address)
    }

    if (address) {
      return address
    }
    return ''
  } catch (error) {
    return ''
  }
}
