export const GRAPH_API_PROFILE = process.env.NEXT_PUBLIC_GRAPH_API_PROFILE
export const GRAPH_API_PREDICTION = process.env.NEXT_PUBLIC_GRAPH_API_PREDICTION
export const GRAPH_API_LOTTERY = process.env.NEXT_PUBLIC_GRAPH_API_LOTTERY
export const SNAPSHOT_BASE_URL = process.env.NEXT_PUBLIC_SNAPSHOT_BASE_URL
export const API_PROFILE = process.env.NEXT_PUBLIC_API_PROFILE
export const API_NFT = process.env.NEXT_PUBLIC_API_NFT
export const SNAPSHOT_API = `${SNAPSHOT_BASE_URL}/graphql`
export const SNAPSHOT_HUB_API = `${SNAPSHOT_BASE_URL}/api/message`

/**
 * V1 will be deprecated but is still used to claim old rounds
 */
export const GRAPH_API_PREDICTION_V1 = 'https://api.thegraph.com/subgraphs/name/pancakeswap/prediction'

export const INFO_CLIENT = 'https://bsc.streamingfast.io/subgraphs/name/pancakeswap/exchange-v2'
export const BLOCKS_CLIENT = 'https://api.thegraph.com/subgraphs/name/pancakeswap/blocks'
export const GRAPH_API_NFTMARKET = process.env.NEXT_PUBLIC_GRAPH_API_NFT_MARKET
export const GRAPH_HEALTH = 'https://api.thegraph.com/index-node/graphql'

export const TC_MOBOX_SUBGRAPH = 'https://api.thegraph.com/subgraphs/name/pancakeswap/trading-competition-v3'

export const BASE_API_LIVE_SWAP = process.env.NEXT_PUBLIC_API_LIVE_SWAP

export const TOKEN_PAYX = process.env.NEXT_PUBLIC_TOKEN as string
export const TOKEN_SMC_PAYX = process.env.NEXT_PUBLIC_SMC_PAYX as string
export const TOKEN_PRICE_SMC_FEED = process.env.NEXT_PUBLIC_SMC_PRICE_FEED as string
export const API_GRAPH = process.env.NEXT_PUBLIC_API_GRAPH as string
export const API_META_NFT = process.env.NEXT_PUBLIC_API_META_NFT as string
export const ADDRESS_SMC_AUCTION = process.env.NEXT_PUBLIC_SMC_AUCTION as string
export const ADDRESS_SMC_SHARING = process.env.NEXT_PUBLIC_SMC_SHARING as string
export const SHARING_ROLE_KEY = process.env.NEXT_PUBLIC_SHARING_ROLE_KEY as string

// PayX Ino
export const USDT_TOKEN = process.env.NEXT_PUBLIC_USDT as string
export const SMC_INO_CONTRACT = process.env.NEXT_PUBLIC_SMC_INO as string
export const PAYX_INO_NFT_CONTRACT = process.env.NEXT_PUBLIC_NFT_CONTRACT as string
