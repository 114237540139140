import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88438 24.3119C6.36147 24.0337 6.97376 24.1949 7.25198 24.672C8.35446 26.5626 10.0399 27.9923 12.1184 28.92C12.6228 29.1451 12.8491 29.7364 12.624 30.2407C12.3989 30.745 11.8076 30.9714 11.3033 30.7463C8.88794 29.6683 6.86154 27.9727 5.52427 25.6795C5.24606 25.2024 5.40729 24.5901 5.88438 24.3119Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.207 5.72909C23.8552 4.34845 21.9272 3.83919 20.0398 4.37623C19.5086 4.52738 18.9555 4.21929 18.8043 3.68809C18.6532 3.15689 18.9613 2.60373 19.4925 2.45259C22.0767 1.71727 24.7653 2.4193 26.636 4.32984C27.0224 4.72445 27.0158 5.35758 26.6212 5.74397C26.2266 6.13037 25.5934 6.1237 25.207 5.72909Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9195 4.91102C14.6341 4.42159 13.8024 4.12477 12.9575 4.60764C12.1156 5.08874 11.9676 5.93679 12.248 6.41771L15.6156 12.1925C15.8938 12.6696 15.7326 13.2819 15.2555 13.5601C14.7784 13.8383 14.1661 13.6771 13.8879 13.2L9.39782 5.50026C9.11241 5.01083 8.28066 4.71401 7.43576 5.19688C6.59396 5.67798 6.4459 6.52603 6.72634 7.00695L11.7777 15.6692C12.0559 16.1463 11.8946 16.7586 11.4176 17.0368C10.9405 17.315 10.3282 17.1538 10.05 16.6767L7.80493 12.8268C7.51952 12.3374 6.68777 12.0405 5.84287 12.5234C5.00106 13.0045 4.85301 13.8526 5.13345 14.3335L10.1848 22.9957C12.33 26.6745 17.6238 27.8683 22.1131 25.3027C26.5993 22.7387 28.1941 17.6168 26.0538 13.9465L22.6862 8.17166C22.4008 7.68223 21.5691 7.38541 20.7242 7.86828C19.8824 8.34938 19.7343 9.19743 20.0148 9.67835L22.2598 13.5282C22.3941 13.7586 22.4309 14.0332 22.3618 14.2908C22.2928 14.5485 22.1237 14.7678 21.8921 14.9002C19.8355 16.0756 19.2052 18.3483 20.1056 19.8923C20.3838 20.3694 20.2226 20.9817 19.7455 21.2599C19.2684 21.5381 18.6561 21.3769 18.3779 20.8998C17.161 18.8129 17.6527 16.3 19.2245 14.5239C19.664 14.0274 19.8145 13.3051 19.4804 12.7323L14.9195 4.91102Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
