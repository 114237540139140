import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none" {...props}>
      <path d="M0 0H2L4 4L2 8H0L2 4L0 0Z" fill="#FFDA55" />
      <path d="M4 0H6L8 4L6 8H4L6 4L4 0Z" fill="#FFDA55" />
      <path d="M8 0H10L12 4L10 8H8L10 4L8 0Z" fill="#FFDA55" />
    </Svg>
  )
}

export default Icon
