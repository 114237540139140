import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="44" height="45" viewBox="0 0 44 45" fill="none" {...props}>
      <g filter="url(#filter0_d_18624_15595)">
        <rect x="18" y="18.5" width="8" height="8" rx="4" fill="url(#paint0_linear_18624_15595)" />
      </g>
      <path
        d="M3.5 11.7883L22 1.07775L40.5 11.7883V33.2117L22 43.9223L3.5 33.2117V11.7883Z"
        stroke="url(#paint1_linear_18624_15595)"
      />
      <defs>
        <filter
          id="filter0_d_18624_15595"
          x="9"
          y="9.5"
          width="26"
          height="26"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.603576 0 0 0 0 0.866634 0 0 0 0 0.979374 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_18624_15595" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_18624_15595" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_18624_15595"
          x1="23.5313"
          y1="18.5"
          x2="23.5503"
          y2="26.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0473389" stopColor="#01CAFF" />
          <stop offset="1" stopColor="#01FFFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_18624_15595"
          x1="29.2735"
          y1="0.5"
          x2="29.395"
          y2="44.4997"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0473389" stopColor="#01CAFF" />
          <stop offset="1" stopColor="#01FFFF" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
