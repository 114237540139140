import { Box, Flex, Text } from '@pancakeswap/uikit'
import InputCopy from 'components/CoppyItem/InputCopy'
import { WalletType } from 'config/types/Wallet'
import { useTranslation } from 'contexts/Localization'
import { formatCode } from 'helpers/CommonHelper'
import Link from 'next/link'
import { memo } from 'react'
import styled from 'styled-components'

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-top: 10px;

  background: rgba(250, 255, 255, 0.05);
  backdrop-filter: blur(15px);

  .user-box {
    img {
      width: 50px;
      height: 50px;
      margin-right: 16px;
    }

    > div {
      svg {
        fill: #969999;
        width: 16px;
        height: 16px;
      }

      p {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        color: #faffff;
      }

      .address-copy {
        padding: 0;
        max-width: 110px;

        .wrapper-input {
          padding: 0;
          border: none;

          .ant-input-affix-wrapper {
            padding: 0;
          }

          input.ant-input {
            color: #969999;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }

    > svg {
      cursor: pointer;
      margin-left: 16px;
    }
  }

  .sign-out-btn {
    cursor: pointer;

    :hover {
      color: #00a0ff;
    }
  }

  .wrapper-input {
    border: none;
  }
`

const DropdownUserMenu = ({
  userData,
  toggleMenu,
  onSignOut,
  myWallet,
}: {
  userData: any
  toggleMenu: () => void
  onSignOut: () => void
  myWallet: WalletType
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper onClick={toggleMenu}>
      <Flex alignItems="center" justifyContent="space-between" className="user-box">
        <Flex>
          <Flex as="img" src={userData?.avatar} alt="" alignItems="center" justifyContent="center" />
          <Flex flexDirection="column">
            <p>{userData?.fullName}</p>

            <InputCopy
              className="address-copy"
              value={myWallet?.address}
              displayValue={formatCode(myWallet?.address, 5, 5)}
            />
          </Flex>
        </Flex>
      </Flex>

      <Link href="/profile" passHref>
        <Text fontSize="18px" lineHeight="24px" fontWeight={700} mt="12px" className="sign-out-btn">
          {t('My Profile')}
        </Text>
      </Link>

      <Text fontSize="18px" lineHeight="24px" fontWeight={700} mt="12px" className="sign-out-btn" onClick={onSignOut}>
        {t('Sign out')}
      </Text>
    </Wrapper>
  )
}

export default memo(DropdownUserMenu)
