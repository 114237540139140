import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.68472 1.99622C7.3139 0.809239 8.56185 0 10 0C11.4382 0 12.6861 0.809239 13.3153 1.99621C14.5995 1.60179 16.0541 1.912 17.0711 2.92893C18.088 3.94586 18.3982 5.40051 18.0038 6.68472C19.1908 7.3139 20 8.56185 20 10C20 11.4382 19.1908 12.6861 18.0038 13.3153C18.3982 14.5995 18.088 16.0541 17.0711 17.0711C16.0541 18.088 14.5995 18.3982 13.3153 18.0038C12.6861 19.1908 11.4382 20 10 20C8.56184 20 7.3139 19.1908 6.68472 18.0038C5.40051 18.3982 3.94586 18.088 2.92893 17.0711C1.91201 16.0541 1.6018 14.5995 1.99622 13.3153C0.80924 12.6861 0 11.4382 0 10C0 8.56185 0.809239 7.3139 1.99622 6.68472C1.60179 5.40051 1.912 3.94586 2.92893 2.92893C3.94586 1.912 5.40051 1.60179 6.68472 1.99622ZM14.7625 7.49058C14.8357 7.5638 14.8357 7.68252 14.7625 7.75574L8.84045 13.6778C8.76723 13.751 8.64851 13.751 8.57529 13.6778L5.30492 10.4074C5.23169 10.3342 5.23169 10.2155 5.30492 10.1422L6.36558 9.08157C6.4388 9.00834 6.55752 9.00834 6.63074 9.08157L8.57529 11.0261C8.64851 11.0993 8.76723 11.0993 8.84045 11.0261L13.4366 6.42992C13.5099 6.35669 13.6286 6.35669 13.7018 6.42992L14.7625 7.49058Z"
        fill="#1DA1F2"
      />
      <path
        d="M14.7625 7.75574C14.8357 7.68252 14.8357 7.5638 14.7625 7.49058L13.7018 6.42992C13.6286 6.35669 13.5099 6.35669 13.4366 6.42992L8.84045 11.0261C8.76723 11.0993 8.64851 11.0993 8.57529 11.0261L6.63074 9.08157C6.55752 9.00834 6.4388 9.00834 6.36558 9.08157L5.30492 10.1422C5.23169 10.2155 5.23169 10.3342 5.30492 10.4074L8.57529 13.6778C8.64851 13.751 8.76723 13.751 8.84045 13.6778L14.7625 7.75574Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
