import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="44" height="45" viewBox="0 0 44 45" fill="none" {...props}>
      <path
        d="M6.03235 24.2617C6.18274 24.2617 6.18274 24.2617 6.03235 24.2617C6.78001 24.2617 7.37727 23.8148 7.37727 23.0672C7.37727 22.6203 7.52766 22.1691 7.52766 21.7223C8.5761 16.1879 12.6109 11.8523 17.6941 10.2109C18.4417 11.8566 20.2378 13.0512 22.18 13.0512C24.8699 13.0512 27.1128 10.8082 27.1128 8.11836C27.1128 5.42852 24.8699 3.18555 22.18 3.18555C19.4902 3.18555 17.3933 5.27812 17.2472 7.82187C11.1156 9.75976 6.47922 14.843 5.28469 21.4215C5.1343 21.8684 5.1343 22.4699 5.1343 22.9168C4.83352 23.5141 5.43508 24.1156 6.03235 24.2617ZM29.807 38.1707C29.5062 37.5734 28.7585 37.423 28.1613 37.7238C27.7144 37.8742 27.4136 38.0246 26.9667 38.1707C21.7331 40.1129 16.0527 38.768 12.0136 35.1801C13.062 33.6848 13.2081 31.5922 12.164 29.9465C10.8191 27.5531 7.67805 26.6551 5.28469 28.1504C2.89133 29.4953 1.99329 32.6363 3.4886 35.0297C4.83352 37.2727 7.82415 38.1707 10.0671 36.9719C14.7034 41.3074 21.5784 42.8027 27.8605 40.5598C28.3074 40.4094 28.7585 40.259 29.2054 39.9625C29.9574 39.666 30.2538 38.9184 29.807 38.1707C29.9574 38.1707 29.807 38.1707 29.807 38.1707ZM38.9292 28.4512C40.4245 22.1691 38.332 15.5906 33.2488 11.2551C32.8019 10.9543 32.5011 10.6578 32.0542 10.357C31.457 9.91016 30.7093 10.0562 30.2581 10.6578V10.8082C29.9574 11.4055 30.1077 12.1531 30.705 12.4539C31.0058 12.7547 31.4527 12.9008 31.7534 13.2016C36.089 16.7895 37.7347 22.3238 36.6863 27.5574C34.8902 27.407 32.948 28.3051 32.0499 30.1012C30.705 32.4859 31.4527 35.4766 33.8417 36.9719C36.2351 38.3168 39.2257 37.5691 40.721 35.1758C41.7695 32.7867 41.1679 29.7961 38.9292 28.4512Z"
        fill="#06E0FE"
      />
      <path
        d="M28.1609 20.5277H16.048C15.3004 20.5277 14.7031 19.9305 14.7031 19.1828C14.7031 18.4352 15.3004 17.8379 16.048 17.8379H28.1609C28.9086 17.8379 29.5059 18.4352 29.5059 19.1828C29.3555 19.9262 28.7582 20.5277 28.1609 20.5277ZM28.1609 25.6109H16.048C15.3004 25.6109 14.7031 25.0137 14.7031 24.266C14.7031 23.5184 15.3004 22.9211 16.048 22.9211H28.1609C28.9086 22.9211 29.5059 23.5184 29.5059 24.266C29.3555 25.1598 28.7582 25.6109 28.1609 25.6109ZM28.1609 30.8445H16.048C15.3004 30.8445 14.7031 30.2473 14.7031 29.4996C14.7031 28.752 15.3004 28.1547 16.048 28.1547H28.1609C28.9086 28.1547 29.5059 28.752 29.5059 29.4996C29.3555 30.243 28.7582 30.8445 28.1609 30.8445Z"
        fill="#06E0FE"
      />
    </Svg>
  )
}

export default Icon
